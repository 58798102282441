.section {
  display: flex;
  gap: var(--spacing-7);
  justify-content: center;
  margin: auto;
}

.contentWrapper {
  width: 100%;
  max-width: var(--max-width);
  padding: var(--spacing-7);
}

.cardsWrapper {
  flex: 1;
}

.content {
  display: flex;
  gap: var(--spacing-7);
  align-items: flex-start;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-7);
}
