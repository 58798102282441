.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #9c9c9ca1;
  opacity: 1;
  backdrop-filter: blur(3px);
}

.modalWrapper {
  display: flex;
  align-items: flex-start;
  height: fit-content;
  max-height: calc(100vh - 30px);
  margin: 30px 0 0;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 804px;
  padding: var(--spacing-5);
  background: var(--border-color-invert);
  border-radius: 5px;
}

.title {
  padding: var(--spacing-5);
  font-size: var(--H6-line-height);
  color: var(--font-color-base);
  text-align: center;
}

.picture {
  position: absolute;
  top: 30px;
  right: 30px;
  width: var(--spacing-7);
  height: var(--spacing-7);
}

.form {
  width: 562px;
  padding: var(--spacing-5) 0;
}

.wrap {
  position: relative;
  top: -6px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-around;
  height: 310px;
}

.wrap-order {
  height: 230px;
}

.star {
  color: #eb0d3f;
}

.text {
  font-size: var(--normalRegular-font-size);
  color: #121211;
  text-align: end;
  white-space: nowrap;
}

.input {
  width: 100%;
  height: 56px;
}

.inputMask {
  flex-grow: 1;
  width: 100%;
  height: 56px;
  padding: 0 var(--spacing-6);
  background-color: transparent;
  border: 1px solid var(--background-color-fields);
  border-radius: var(--spacing-10);
}

.errorPhone {
  margin-top: 10px;
  border: 1px solid var(--border-color-warning);
}

.field::placeholder,
.inputMask::placeholder {
  color: #b3b3b3;
}

.btn {
  align-items: center;
  justify-content: center;
  height: 43px;
  margin: var(--spacing-5) 0;
  font-size: var(--largeBold-font-size);
  background-color: #e65c4f;
}

.agreement {
  font-size: var(--normalRegular-font-size);
  color: var(--background-Button-Hover);
  text-align: initial;
}

.license {
  font-size: var(--normalRegular-font-size);
  color: var(--border-color-focused-input);
}

.message {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 392px;
  height: 280px;
  padding: var(--spacing-4);
  text-align: center;
  background: var(--border-color-invert);
  border-radius: 5px;
  transform: translateX(-50%);
}

.titleMes {
  padding-bottom: 20px;
  font-size: var(--H1-font-size);
  font-weight: var(--H1-font-weight);
  color: #326789;
}

.subtitle {
  font-size: var(--largeRegular-font-size);
  font-weight: var(--largeRegular-font-weight);
  line-height: var(--largeRegular-line-height);
  color: var(--font-color-base);
}

.container {
  display: block;
  width: 100%;
}

.error {
  margin: 10px 0;
  font-size: small;
  color: var(--font-color-warning);
  text-align: start;
  border-radius: var(--spacing-9);
}

.main {
  display: flex;
  gap: 10px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;

  >*:not(:last-child) {
    margin-bottom: 17px;
  }
}
