.warningText {
  display: flex;
  gap: var(--spacing-9);
  align-items: flex-start;
  font-size: var(--smallRegular-font-size);
  line-height: var(--smallRegular-line-height);
  color: var(--font-color-warning);

  svg {
    min-width: var(--spacing-8);
    min-height: var(--spacing-8);
    margin-top: 4px;
  }
}

.absolute {
  position: absolute;
  bottom: calc(-1.1 * var(--spacing-7));
  left: 0;
  font-size: 12px;
}
