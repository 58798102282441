@import '/src/styles/shared/breakpoints.scss';

.wrapper {
  display: flex;
  justify-content: center;
}

.container {
  position: relative;
  width: 100%;
  max-width: var(--max-width);
  height: 100vh;
}

.layout {
  display: grid;
  grid-template-areas:
    'header header' auto 1fr
    'adminNav content' auto 1fr;
}

.main {
  position: absolute;
  left: 260px;
  z-index: 8;
  grid-area: content;
  width: calc(100% - 260px);
  max-height: 100vh;
  padding: 0 var(--spacing-3) var(--spacing-5) var(--spacing-3);

  .userRole {
    padding: var(--spacing-7) 0;
    margin: 0 0 50px;
    font-size: var(--extraLargeRegular-font-size);
    font-weight: var(--extraLargeRegular-font-weight);
    line-height: var(--extraLargeRegular-line-height);
    text-align: right;
  }
}
