%input {
  flex: 1;
  width: 100%;
  max-height: 40px;
  padding: 16px 20px;
  border: 1px solid var(--background-color-button-secondary);
  border-radius: 4px;
}

.form {
  display: grid;
  grid-template:
    'category parametr'
    'nameDescription parametr'
    'nameDescription check' 'saveBtn saveBtn' min-content;
  grid-template-columns: 1fr 1fr;
  row-gap: var(--spacing-5);
  column-gap: var(--spacing-1);
}

.category {
  position: relative;
  grid-area: category;
}

.parametr {
  grid-area: parametr;
}

.nameDescription {
  grid-area: nameDescription;
}

.check {
  display: flex;
  flex-direction: column;
  grid-area: check;
  gap: var(--spacing-8);
  justify-content: flex-end;
}

.label,
.priceLabel {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-9);
  height: 100%;
}

.price {
  display: flex;
  gap: var(--spacing-8);

  & label {
    max-width: 50%;
  }
}

.input {
  @extend %input;
}

.textarea {
  @extend %input;
}

.saveBtn {
  display: flex;
  grid-area: saveBtn;
  gap: var(--spacing-8);
  align-items: center;
  justify-content: flex-end;
}

.buttonWrapper {
  display: flex;
  justify-content: flex-end;
}

.errorMessage {
  color: var(--font-color-error);
}

.optionWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-9);
  padding-top: var(--spacing-4);
}

.parametrWrapper {
  display: flex;
  gap: var(--spacing-8);
  justify-content: space-between;
}

.plusWrapper {
  display: flex;
  min-width: 50px;
  cursor: pointer;
}

.plus {
  flex: 1;
}

.span {
  margin-bottom: var(--spacing-9);
}

.description {
  margin-top: var(--spacing-5);
}

.param {
  margin-bottom: 10px;
}

.warningText {
  position: absolute;
  bottom: calc(-1.25 * var(--spacing-7));
  left: 0;
  display: flex;
  gap: var(--spacing-9);
  align-items: center;
  font-size: var(--smallRegular-font-size);
  line-height: var(--smallRegular-line-height);
  color: var(--font-color-warning);
  white-space: nowrap;
}
