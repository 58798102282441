.solutionVideo {
  width: 100%;

  &:first-child {
    margin-top: var(--spacing-5);
    margin-bottom: var(--spacing-7);
  }
}

.solutionVideoEdit {
  display: flex;
  justify-content: space-between;

  & label {
    flex: auto;
  }

  & .editButtons {
    box-sizing: border-box;
    display: flex;
    align-self: flex-end;
    justify-content: space-between;
    justify-self: flex-end;
    margin-bottom: var(--spacing-9);

    & button {
      margin-left: var(--spacing-6);
    }
  }
}

.buttonsBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--spacing-4);

  & button:first-child {
    min-width: 7rem;
    height: 34px;
    padding: 0;
    margin-top: var(--spacing-7);
  }
}

.saveBtn {
  display: flex;
  grid-area: saveBtn;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  width: 120px;
  height: 34px;
  padding: var(--spacing-8) var(--spacing-8);
  margin-top: var(--spacing-7);
  margin-left: var(--spacing-10);

  &:active {
    transform: scale(0.97);
  }

  &:disabled {
    transform: scale(1);
  }
}
