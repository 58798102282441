.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: var(--spacing-5);
  background: var(--border-color-invert);
}

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
}

.cancel {
  font-size: 16px;
  cursor: pointer;
}

.wrapper {
  width: 515px;
  font-size: 40px;
  transform: translate(0, 50%);
}

.title {
  padding: 0 0 var(--spacing-5);
  font-size: var(--normalBold-line-height);
  text-align: center;
}

.form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  >*:not(:last-child) {
    margin-bottom: 40px;
  }
}

.main {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.star {
  color: #eb0d3f;
}

.item {
  width: 430px;
}

.input {
  width: 100%;
  height: 56px;
}

.btn {
  align-items: center;
  justify-content: center;
  max-width: 134px;
  height: 34px;
  font-size: 14px;
}
