@import '../../style.module.scss';

.errorMessage {
  color: var(--font-color-error);
}

.label {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--spacing-9);
  height: 100%;
}

.input {
  @extend %input;
  border: none;
  opacity: 1;

  &:hover,
  &:focus {
    border: none;
  }
}

.inputActive {
  border-color: var(--background-color-button-secondary);
}

.customInput {
  @extend %input;
  display: flex;
  min-width: 100%;
  padding: 0;

  &.m {
    max-width: 150px;
  }

  &.inputDisabled {
    border: 1px solid var(--background-color-inactive);
  }
}

.inputError {
  border: 1px solid var(--border-color-warning);
}

.prefix {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-9);
}

.inputWithPrefix {
  padding-left: 0;
}

.prefixHide {
  display: none;
}

.prefixDisabled {
  background: #efefef4d;
}

