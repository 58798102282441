@import '../../style.module.scss';

.label {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-9);
  height: 100%;
}

.select {
  @extend %select;
}

.selectError {
  border: 1px solid var(--border-color-warning);
}
