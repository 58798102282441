.imageLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 42px;
  padding: 20px;
  border-bottom: 1px solid rgb(179, 179, 179);
}

.imageLineData {
  display: flex;
  align-items: center;
  width: 83%;

  & img {
    width: 44px;
    aspect-ratio: 1/1;
    object-fit: contain;
    margin-right: var(--spacing-6);
  }

  & div span {
    margin-top: var(--spacing-6);
  }

  & span {
    font-size: 14px;
    color: rgb(102, 102, 102);
  }
}

.imageInputs {
  width: 100%;
}

.editButtons {
  box-sizing: border-box;
  display: flex;
  align-self: center;
  justify-content: space-between;

  & button {
    margin-left: var(--spacing-6);
  }
}

.saveBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  width: 211px;
  height: 34px;
  padding: var(--spacing-8) var(--spacing-8);
  margin-left: var(--spacing-10);

  &:active {
    transform: scale(0.97);
  }

  &:disabled {
    transform: scale(1);
  }
}

.buttonsBlock {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--spacing-5);
  margin-bottom: 20px;

  & button:first-child {
    min-width: 7rem;
    height: 34px;
    padding: 0;
  }
}
