@import '/src/styles/shared/breakpoints.scss';

%link {
  display: flex;
  gap: var(--spacing-9);
  align-items: center;
  transition: opacity 0.5s;
}

.card {
  @include breakpoint(lg) {
    max-width: 48%;
  }

  @include breakpoint(xl) {
    max-width: 32%;
  }
  position: relative;
  flex-basis: 100%;
  align-self: center;
  min-width: 290px;
  max-width: 400px;
  min-height: 250px;
  overflow: hidden;
  border: 2px solid #fff3e9;
  border-radius: 8px;

  &:hover {
    box-shadow: 0 0 10px 0 #d2671039;

    .linkWrapper {
      opacity: 1;
      transition: all ease-in-out 0.2s;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  height: 190px;
  padding: var(--spacing-7) 20px;
  border-radius: 0 0 8px 8px;
}

.wrap {
  min-height: 130px;
  padding: 20px;
  background-color: #fff3e9;
  border-radius: 8px 8px 0 0;
}

.title {
  padding-bottom: var(--spacing-8);
  overflow: hidden;
  font-size: var(--smallBold-line-height);
  font-weight: var(--normalBold-font-weight);
  line-height: var(--normalBold-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.iconWrapper {
  display: flex;
  gap: var(--spacing-8);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.icon {
  height: var(--spacing-6);
}

.list {
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}

.descriptionWrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
}

.checkedCard {
  border-color: var(--background-color-button-secondary);
  transition: all ease-in-out 0.3s;
  transform: scale(1.02);
}

.text {
  overflow: hidden;
  font-size: var(--smallBold-font-size);
  font-weight: var(--smallRegular-font-weight);
  line-height: var(--normalRegular-line-height);
}

.price {
  flex: 1;
  margin-top: var(--spacing-7);
  font-size: var(--normalBold-font-size);
  font-weight: var(--normalRegular-font-weight);
  line-height: var(--normalRegular-line-height);
}

.favoriteIcon {
  width: 25px;
  height: 25px;
}

.flag {
  display: flex;
  align-items: flex-start;
}

.notFavorite {
  path {
    fill: var(--background-color);
    stroke: rgb(252, 180, 0);
  }
}

.favorite {
  justify-self: center;

  & > svg path {
    fill: rgb(252, 180, 0);
  }
}

.selectIconWrapper {
  position: absolute;
  right: var(--spacing-8);
  bottom: var(--spacing-8);
  z-index: 20;
}

.linkWrapper {
  display: flex;
  align-items: center;
  opacity: 0;
}

.link {
  position: relative;
  font-size: var(--smallRegular-font-size);
  font-weight: var(--normalBold-font-weight);
  line-height: var(--smallRegular-line-height);
  color: #121211;
  transition: 0.3s all ease-in-out;

  &:hover {
    color: rgb(255, 0, 0);
  }

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    right: -14px;
    display: block;
    width: 0;
    height: 50%;
    margin-top: -1px;
    content: '';
    border-right: 1px solid;
    transform: translateY(-50%) rotate(135deg);
  }

  &::after {
    margin-top: 1px;
    transform: rotate(45deg);
  }
}
