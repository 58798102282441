@import '/src/styles/shared/breakpoints.scss';

.main {
  display: flex;
  flex-direction: column;
  grid: 'main';
  min-height: 100vh;
}

.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--spacing-6);
  align-items: center;
  justify-content: space-between;
}

.header {
  display: flex;
  flex: 1;
  grid: 'header';
}

.outlet {
  width: 100%;
  margin: 160px auto 0;
}
