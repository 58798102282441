.editingWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  min-width: 350px;
}

.buttonWrapper {
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: flex-end;
}
