.usersPageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 var(--spacing-6) 0;

  .formSearch {
    display: flex;
    gap: var(--spacing-6);
    max-width: 492px;
  }

  .selectAmountUsers {
    width: 85px;
  }
}
