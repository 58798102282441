.wrapper,
.list {
  position: relative;
  width: 100%;
}

.button {
  width: 100%;
  padding-right: 0;
  text-align: end;
}

.warningText {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  gap: var(--spacing-9);
  align-items: center;
  font-size: var(--smallRegular-font-size);
  line-height: var(--smallRegular-line-height);
  color: var(--font-color-warning);
}

.label {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-9);
  height: 100%;
  padding-top: 20px;
}

.textareaWrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--background-color-inactive);

  &:hover {
    border: 1px solid var(--background-color-button-secondary);
  }

  &:active,
  &:focus {
    border: 1px solid var(--background-color-button-secondary);
  }

  &:disabled input {
    color: var(--font-color-disabled);
  }
}

.labelTextarea {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-9);
  height: 100%;
}

.textarea {
  width: 100%;
  min-height: 95px;
  max-height: 400px;
  padding: var(--spacing-8);
  font-family: var(--normalRegular-font-family);
  font-size: var(--normalRegular-font-size);
  font-weight: var(--normalRegular-font-weight);
  line-height: var(--normalRegular-line-height);
  resize: none;
  border: none;
  outline: none;
}

.textareaWrapper,
.textarea {
  border-radius: 4px;
}

.inputError {
  border: 1px solid var(--border-color-warning);
}

.count {
  display: flex;
  justify-content: flex-end;
  font-size: var(--smallRegular-font-size);
  color: #b3b3b3;
}

.expanded {
  height: 225px;
}

.field {
  // resize: none;
  position: relative;
  flex: 1;
  border: 1px solid var(--background-color-fields);
}
