@import '/src/styles/shared/breakpoints.scss';

.header {
  position: fixed;
  top: 0;
  z-index: 9;
  display: flex;
  grid-area: header;
  align-items: center;
  width: 100%;
  max-width: var(--max-width);
  min-height: 62px;
  padding: var(--spacing-7) var(--spacing-5);

  p {
    font-size: var(--extraLargeRegular-font-size);
    font-weight: var(--extraLargeRegular-font-weight);
    line-height: var(--extraLargeRegular-line-height);
  }
}

.displayNone {
  display: none;
}

.displayBlock {
  display: block;
}
