.settings {
  display: flex;
  translate: 10%;

  &__title {
    margin: 5px 0;
    font-size: var(--spacing-7);
    font-weight: var(--normalBold-font-size);
    line-height: 130%;
    color: var(--font-color-base);
  }

  &__title {
    font-size: var(--spacing-6);
    font-weight: var(--normalBold-font-size);
    color: var(--font-color-base);
  }
}

.form {
  font-size: var(--spacing-7);
  font-weight: var(--normalRegular-font-size);
  line-height: 145%;
  color: #333333;

  &__title {
    padding: 5px 0;
    margin-top: 15px;
    font-size: var(--spacing-7);
    font-weight: var(--normalRegular-font-size);
    line-height: 145%;
    color: #333333;
  }

  &__button {
    width: 240px;
    height: 56px;
    margin: 25px 0;
    color: var(--background-color);
    letter-spacing: 0.0002em;
    background: var(--font-color-base);
    border: none;

    &:hover {
      cursor: pointer;
      background: var(--font-color-hover);
    }

    &:disabled {
      background: var(--font-color-disabled);
    }
  }
}

.span {
  margin: 20px;
}
