.form {
  width: 430px;
  padding-bottom: 40px;
  margin-top: var(--spacing-5);
  font-size: var(--spacing-7);
  font-weight: var(--normalRegular-font-size);
  line-height: 145%;
  color: #333333;

  .title {
    padding: 5px 0;
    margin-top: 15px;
    font-size: var(--spacing-7);
    font-weight: var(--normalRegular-font-size);
    line-height: 145%;
    color: #333333;
  }

  .inputGroupWrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-6);
  }

  .button {
    width: 240px;
    padding: var(--spacing-8) var(--spacing-5);
    margin: var(--spacing-5) 0;
    color: var(--background-color);
    letter-spacing: 0.0002em;
    background: var(--font-color-second-accent);
    border: none;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      background: var(--font-color-second-accent);
    }

    &:disabled {
      background: var(--font-color-disabled);
    }
  }

  .span {
    margin: 20px;
  }

  .error {
    color: var(--font-color-error);
  }

  .success {
    color: #4caf50;
    text-transform: capitalize;
  }
}

.inputMask {
  flex-grow: 1;
  width: 100%;
  height: 56px;
  padding: 0 var(--spacing-6);
  background-color: transparent;
  border: 1px solid var(--background-color-inactive);
  border-radius: var(--spacing-9);
}

.field::placeholder,
.inputMask::placeholder {
  color: var(--font-color-disabled);
}

.text {
  margin-bottom: 10px;
  font-size: 14px;
}

.warningText {
  position: absolute;
  left: 0;
  display: flex;
  gap: var(--spacing-9);
  align-items: center;
  margin: 4px 0 0;
  font-size: var(--smallRegular-font-size);
  line-height: var(--smallRegular-line-height);
  color: var(--font-color-warning);
}

.emailGroup {
  display: flex;
  gap: 30px;
  align-items: last baseline;
  justify-content: space-between;
  min-width: 570px;
}

.span {
  color: var(--border-color-focused);
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #9c9c9ca1;
  opacity: 1;
  backdrop-filter: blur(3px);
}

.message {
  position: absolute;
  top: 30%;
  left: 50%;
  width: 500px;
  padding: var(--spacing-1);
  text-align: center;
  background: var(--border-color-invert);
  border-radius: 5px;
  transform: translateX(-50%);
}

.picture {
  position: absolute;
  top: 30px;
  right: 30px;
}

.icon {
  margin-top: -40px;
  margin-bottom: var(--spacing-5);
}

.subtitle {
  font-size: var(--largeRegular-font-size);
  font-weight: var(--largeRegular-font-weight);
  line-height: var(--largeRegular-line-height);
}

.subtitle span {
  font-size: var(--extraLargeRegular-font-size);
  color: var(--border-color-focused);
}
