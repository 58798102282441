@import '/src/styles/shared/breakpoints.scss';

.header {
  position: fixed;
  top: 0;
  z-index: 200;
  padding: 0 var(--spacing-7);
  background-color: #ffffff;
  transition: transform 0.2s linear;

  &.hide {
    transition: transform 0.2s linear;
    transform: translateY(-52px);
  }
}

.header__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: var(--spacing-5);
}

.subheader {
  @include breakpoint(md) {
    z-index: 3;
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-8) var(--spacing-5);
  }
}

.linkWrapper {
  @include breakpoint(md) {
    display: flex;
    gap: var(--spacing-9);
    align-items: center;
    justify-content: flex-end;
    font-family: var(--normalRegular-font-family);
    font-size: var(--normalRegular-font-size);
    font-weight: var(--normalRegular-font-weight);
    line-height: var(--normalRegular-line-height);
  }
}

.signIn {
  @include breakpoint(md) {
    display: flex;
    align-items: center;
    padding: 0 var(--spacing-9);
    border-right: solid 1px #4c4c4c;
  }
}

.signUp {
  @include breakpoint(md) {
    display: flex;
    gap: var(--spacing-9);
    align-items: center;
    cursor: pointer;
  }
}

.button {
  display: flex;
  flex-direction: row;
  gap: var(--spacing-9);
  align-items: center;
  padding: 0 0 0 var(--spacing-9);
  font: inherit;
  background-color: transparent;
  border: none;
}

.phoneWrapper {
  @include breakpoint(md) {
    display: flex;
    gap: var(--spacing-9);
    align-items: center;
    white-space: nowrap;
  }
}

.dropdown {
  display: flex;
  align-items: center;
  padding-right: var(--spacing-9);
  padding-left: var(--spacing-7);
  background: url('../../assets/icons/footer_language.svg') no-repeat;
}

.lang {
  font-size: var(--smallBold-font-size);
  color: #4c4c4c;
  cursor: pointer;
  border: none;

  &:focus {
    outline-color: transparent;
  }
}

.star {
  width: var(--spacing-7);
  height: var(--spacing-7);
}
