@import '/src/styles/shared/breakpoints.scss';

.nav {
  display: flex;
  flex-direction: column;
  grid-area: nav;
  width: 320px;
}

.link {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
  padding: var(--spacing-8);
  font-size: var(--largeRegular-font-size);
  font-weight: var(--normalRegular-font-size);
  line-height: var(--largeRegular-line-height);
  color: var(--font-color-hover);

  &:hover,
  &:focus,
  &:active {
    color: var(--font-color-hover);
    cursor: pointer;
    background-color: var(--background-color-footer);
    border-radius: 0 8px 8px 0;

    path {
      color: var(--font-color-hover);
    }
  }
}

.link.active {
  color: var(--font-color-hover);
  cursor: pointer;
  background-color: var(--background-color-footer);
  border-radius: 0 8px 8px 0;
}
