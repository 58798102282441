.header {
  display: flex;
  gap: var(--spacing-6);
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--spacing-4);
}

.buttons {
  display: flex;
  gap: var(--spacing-7);
  align-items: center;
}

.headerText {
  display: inline-block;
  padding: var(--spacing-6) var(--spacing-7);
  cursor: pointer;

  &:active,
  &:hover {
    background-color: rgba($color: #000000, $alpha: 0.05);
  }

  &::after {
    position: relative;
    left: 50%;
    display: block;
    width: 0;
    height: 2px;
    content: '';
    background-color: #ffaa00;
    transition: width 0.3s ease-in-out;
    transform: translateX(-50%);
  }

  &:hover::after,
  &:active::after,
  &.active {
    width: 100%;
  }
}
