.menu {
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-7);
  width: 100%;
  height: 100%;
  padding: 15px;
  background-color: var(--background-color);
}

.menuButton {
  display: block;
  float: left;
  padding: var(--spacing-7);
  margin-right: 29px;
  background: none;
  border: 0;
  outline: 0;
  transform-origin: var(--spacing-7) 11px;

  span {
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }

  :focus {
    border: medium none rgb(111, 255, 176);
    outline: 0;
    box-shadow: rgb(111, 255, 176) 0 0 2px 2px;
  }

  :hover {
    span:nth-of-type(1) {
      width: 33px;
    }

    span:nth-of-type(2) {
      width: var(--spacing-4);
    }

    span:nth-of-type(3) {
      width: 30px;
    }
  }

  &.active {
    span:nth-of-type(1) {
      width: var(--spacing-4);
      transform: rotate(45deg) translate(10px, 10px);
    }

    span:nth-of-type(2) {
      pointer-events: none;
      opacity: 0;
    }

    span:nth-of-type(3) {
      width: var(--spacing-4);
      transform: rotate(-45deg) translate(7px, -7px);
    }
  }
}
