.usersPageFilterOptionsContainer {
  .options {
    li {
      margin: 0 0 var(--spacing-9) 0;

      &:last-child {
        margin: 0;
      }

      span {
        color: var(--font-color-hover);
      }
    }
  }

  button {
    padding: var(--spacing-9) var(--spacing-8);
    margin: var(--spacing-8) 0 0 0;
    font-size: var(--smallRegular-font-size);
  }
}
