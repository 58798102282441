.filtersForPlatformsWrapper {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: var(--spacing-5);
  justify-content: space-around;
  width: 100%;
  height: fit-content;
}

.checkboxGroup {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}

.filterName {
  font-size: var(--normalBold-font-size);
  font-weight: var(--normalBold-font-weight);
  line-height: var(--normalBold-line-height);
}
