.platformsWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-7);
  height: 100%;
  overflow: auto;
}

.switcherBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
