@import '../../style.module.scss';

.label {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-9);
  height: 100%;
}

.fileInputDefault {
  display: none;
}

.fileInputCustom {
  @extend %input;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 600px;
  cursor: pointer;
  background-color: var(--background-color);
  background-image: url('../../assets/file_input_background.svg');
  background-repeat: no-repeat;
  background-position: center;

  &:hover {
    border: 1px solid var(--background-color-button-secondary);
  }
}

.fileInputWrapper {
  position: relative;
  height: 100%;
}

.previewImg {
  width: 200px;
}

.errorMessage {
  position: absolute;
  bottom: 12%;
  display: flex;
  gap: var(--spacing-9);
  align-items: center;
  font-size: var(--smallRegular-font-size);
  line-height: var(--smallRegular-line-height);
  color: var(--font-color-warning);
}

.solutionImage {
  max-height: 136px;
  background-image: url('../../assets/file_image_input_background.svg');
  border-color: rgb(0, 0, 0);
  border-style: dashed;

  & img {
    height: 100%;
  }
}

.inputError {
  border: 1px solid var(--border-color-warning);
  border-style: dashed;

  &:hover {
    border: 1px solid var(--border-color-warning);
  }
}

.imageInputDesc {
  font-size: var(--smallRegular-font-size);
  line-height: var(--smallRegular-line-height);
  color: var(--font-color-warning);
}

.solutionImageDesc {
  margin-top: -40px;
}
