.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: all;
  background: rgba(#000000, 0.4);
  opacity: 1;
  transition: opacity 0.5s ease;
  transform: translateY(0);

  &.hidden {
    transform: translateY(-100vh);
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  align-items: center;
  justify-content: space-between;
  width: 300px;
  height: 200px;
  padding: var(--spacing-6);
  background-color: var(--background-color-secondary);
  border-radius: 8px;
  transition: transform 0.7s ease;
  transform: translateY(0);

  div {
    display: flex;
    gap: var(--spacing-7);
  }

  button {
    border-radius: var(--spacing-9);
  }

  p {
    text-align: center;
  }

  &.hidden {
    transform: translateY(-100vh);
  }
}
