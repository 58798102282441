.form {
  display: flex;
  flex-direction: column;

  .inputContainer {
    margin: var(--spacing-7) 0 20px;

    span {
      font-size: var(--smallRegular-font-size);
      font-weight: var(--normalBold-font-weight);
      line-height: var(--smallRegular-line-height);
    }

    input {
      min-height: 50px;
    }
  }

  .addEventButton {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 34px;
    margin-left: auto;
    font-size: var(--smallRegular-font-size);
    line-height: 1;
  }
}

.events {
  margin: 20px 0;
}
