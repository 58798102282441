.eventsList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}

.emptyList {
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
  padding: var(--spacing-8);
  text-align: center;
}

.eventWrapper {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
}
