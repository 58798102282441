.orderCardWrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  justify-content: flex-start;
  width: 480px;
  height: 680px;
  padding: var(--spacing-5);
  border-radius: 12px;
  box-shadow: 2px 2px 14px 4px var(--box-shadow-color-light);
}

.orderCardTitle {
  display: flex;
  justify-content: center;
}

.orderCardImgWrapper {
  min-height: 370px;
}

.orderBtn {
  min-height: 70px;
  font-size: var(--extraLargeBold-font-size);
  font-weight: var(--extraLargeBold-font-weight);
}
