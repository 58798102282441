@import '/src/styles/shared/breakpoints.scss';

.wrapper {
  @include breakpoint(sm) {
    display: flex;
    flex-basis: 33%;
  }

  @include breakpoint(lg) {
    flex-basis: 25%;
  }
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
}

.reset {
  padding: 0;
  margin-bottom: 1rem;
  font-size: medium;
  font-weight: 700;
}


.saveBtn {
  display: flex;
  grid-area: saveBtn;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  height: 34px;
  padding: var(--spacing-9)  var(--spacing-9);
  margin-top: var(--spacing-7);

  &:active {
    transform: scale(0.97);
  }

  &:disabled {
    transform: scale(1);
  }
}


.controlButtons {
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-9);
}

.btnBox {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
  height: 34px;
  color: var(--font-color-hover);
  border-radius: 4px;
  transition: color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    color: var(--font-color-base);
    box-shadow: 0 0 4px 2px var(--border-color-disabled);
  }
}

.btnBox img {
  width: 25px;
  height: 25px;
}

.btnContainer {
  display: flex;
  grid-row: 5/6;
  grid-column: 1/4;
  align-items: baseline;
  justify-content: space-between;
  padding-bottom: var(--spacing-8);
}

.filtersContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-9);
}
