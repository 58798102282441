@import '/src/styles/shared/breakpoints.scss';

.section {
  display: flex;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  align-items: center;
  width: 100%;
  max-width: var(--max-width);
  padding: 0 var(--spacing-7);
}

.list {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
}

.cardTitle {
  display: flex;
}

.cardInfo {
  @include breakpoint(md) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  gap: 141px;
  align-items: center;
  justify-content: center;
}

.cardImage {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    @include breakpoint(md) {
      width: 100%;
      object-fit: cover;
    }
    width: 50%;
    object-fit: cover;
  }
}

.listElement {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;

  p {
    font-size: var(--extraLargeRegular-font-size);
    font-weight: var(--extraLargeRegular-font-weight);
    line-height: var(--extraLargeRegular-height);
  }
}
