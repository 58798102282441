.notification {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 1000;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  justify-content: space-between;
  width: 470px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid rgb(77, 184, 77);
  border-left-width: 5px;
  border-radius: 4px;
  transition: opacity 0.5s ease;
  transform: translateX(-50%);

  &fade-out {
    pointer-events: none;
    opacity: 0;
  }
}

.wrap {
  display: flex;
  gap: 10px;
}

.checkmark {
  width: 20px;
  height: 20px;
  color: transparent;

  &::before {
    font-size: 15px;
    color: rgb(77, 184, 77);
    content: '\2713';
  }
}

.title {
  margin-bottom: 5px;
  font-size: var(--normalRegular-font-size);
  font-weight: var(--normalMedium-font-weight);
}

.text {
  font-size: var(--normalRegular-font-size);
  color: var(--font-color-hover);
}

.picture {
  position: relative;
  top: -5px;
}
