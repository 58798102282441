.tools, .form {
  display: flex;
  gap: var(--spacing-6);
}

.buttonWrapper {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.sortButton {
  svg {
    width: 40px;
    height: 100%;
  }
}

.checkbox {
  margin: 24px 0 0;
}
