.admin {
  display: flex;
  gap: 50px;
  padding: 30px 0;
}

.form {
  display: grid;
}

.text {
  margin: 20px 0;
  font-size: var(--smallRegular-font-size);
  font-weight: var(--smallRegular-font-weight);
  line-height: var(--smallRegular-line-height);
}

.wrap {
  display: flex;
  align-items: center;
  width: 260px;
  margin: 8px 0 20px;
}

.date {
  border: 1px solid var(--background-color-inactive);

  &:hover {
    border: 1px solid var(--background-color-button-secondary);
  }

  &:active,
  &:focus-within {
    border: 1px solid var(--background-color-button-secondary);
  }

  &:disabled input {
    color: var(--font-color-disabled);
  }
}

.date,
.warningDate {
  padding: 10px 20px;
  border-radius: var(--spacing-10);
}

.warningDate {
  border: 1px solid var(--border-color-warning);

  &:hover {
    border: 1px solid var(--background-color-button-secondary);
  }
}

.dateIcon {
  position: relative;
  left: -10px;
  cursor: pointer;
}

.warning {
  align-self: flex-start;
  margin-right: var(--spacing-9);
}

.nameWrapper {
  margin-bottom: var(--spacing-7);
}

.name {
  width: 100%;
  height: 50px;
  border-radius: var(--spacing-10);
}

.btn {
  width: 450px;
  height: 34px;
  margin-top: 30px;
  color: var(--background-color);
  background-color: var(--font-color-second-accent);
  border-radius: var(--spacing-10);
}

/* Стили для контейнера даты */
.react-datepicker {
  display: inline-block;
  padding: 5px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}

/* Стили для поля ввода даты */
.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

/* Стили для выпадающего календаря */
.react-datepicker__calendar-container {
  position: relative;
  top: -20px;
  left: -20px;
  z-index: 1;
  display: none;
  padding: 10px;
  background-color: var(--font-color-invert);
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.calendar {
  position: relative;
  left: -40px;
  cursor: pointer;
}

.count {
  margin-bottom: 10px;
  font-size: var(--smallRegular-font-size);
  font-weight: var(--smallRegular-font-weight);
  line-height: var(--smallRegular-line-height);
  color: var(--font-color-disabled);
  text-align: end;
}

.error {
  display: flex;
  align-items: center;
  margin-top: -20px;
  margin-bottom: 10px;
  font-size: var(--smallRegular-font-size);
  font-weight: var(--smallRegular-font-weight);
  line-height: var(--smallRegular-line-height);
  color: var(--font-color-warning);
}

.container {
  display: flex;
  justify-content: space-between;
}

.reviews {
  width: 520px;
  height: 457px;
  padding: 0 20px;
  padding-bottom: 20px;
  overflow-y: scroll;
}

.list {
  display: block;
}

.item {
  width: 453px;
  height: 161px;
  padding: 10px 20px;
  margin: 45px 0;
  background-color: var(--background-color-review-card);
  border: 1px solid var(--background-color-inactive);
  border-radius: var(--spacing-10);
}

.nameTxt {
  font-size: var(--smallBold-font-size);
  font-weight: var(--smallBold-font-weight);
  line-height: var(--smallBold-line-height);
  color: #121211;
}

.rvTxt {
  height: 92px;
  padding: 10px 0;
  overflow: hidden;
  font-size: var(--smallRegular-font-size);
  font-weight: var(--smallRegular-font-weight);
  line-height: var(--smallRegular-line-height);
  color: var(--border-color);
  text-overflow: ellipsis;
}

.txt {
  font-size: var(--smallRegular-font-size);
  font-weight: var(--smallRegular-font-weight);
  line-height: var(--smallRegular-line-height);
  color: var(--font-color-disabled);
}

.btnSave {
  position: absolute;
  right: 0;
  bottom: 40%;
  width: 120px;
  height: 34px;
  color: var(--font-color-invert);
  background-color: var(--font-color-second-accent);
  border-radius: var(--spacing-10);
}

.btnTxt {
  color: var(--font-color-disabled);
  cursor: pointer;

  &:hover {
    color: var(--font-color-second-accent);
  }
}

.editing {
  border: 2px solid var(--background-color-button-secondary);
  border-radius: var(--spacing-10);
}
