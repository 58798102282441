.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: var(--max-width);
  padding: 0 var(--spacing-7);
}

.card {
  display: flex;
  gap: var(--spacing-4);
  justify-content: center;
}

.cardImage {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    object-fit: cover;
  }
}

.cardInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--spacing-9);
  justify-content: center;
  padding: 0 var(--spacing-1);

  h4 {
    text-transform: uppercase;
  }
}

.infoButton {
  display: flex;
  justify-content: center;
  padding: var(--spacing-1) 0 0 0;
}

@media (max-width: 980px) {
  .opportunityCard {
    flex-direction: column;
  }

  .opportunityCardImage {
    order: 1;
  }

  .opportunityCardInfo {
    text-align: center;
  }
}
