.usersPageFilter {
  position: relative;

  .filterButton {
    display: flex;
    gap: 14px;
    align-items: center;
    padding: 7px 12px;
    cursor: pointer;
    border: 1px solid var(--background-color-secondary);
    border-radius: 8px;

    &:hover {
      background: var(--background-color-secondary);
    }

    .title {
      color: var(--font-color-second-accent);
    }

    .optionsChecked {
      color: #666666;
    }

    &.filterOptionsOpen {
      background: var(--background-color-secondary);

      .icon {
        transform: scaleY(-1);
      }
    }
  }

  .options {
    position: absolute;
    top: calc(100% + var(--spacing-10));
    left: 0;
    z-index: 2;
    padding: var(--spacing-8);
    background-color: var(--background-color);
    border: 1px solid var(--background-color-secondary);
    border-radius: 8px;
    box-shadow: 0 4px 16px 0 rgba(16, 24, 40, 0.08), 0 0 7px 0 rgba(0, 0, 0, 0.03);
  }
}
