.usersPageTableHeader {
  display: flex;
  gap: 26px;
  align-items: center;
  height: 64px;
  padding: 0 70px 0 20px;
  background: var(--background-color-secondary);

  label {
    span::before {
      margin: 0;
    }
  }

  &.usersPageTableWithoutDelete {
    padding: 0 20px;
  }
}
