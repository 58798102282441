.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  max-height: 88%;
  padding-top: var(--spacing-5);
  overflow-y: hidden;
}

.buttonWrapper {
  display: flex;
  flex: 1;
  gap: 10px;
  justify-content: flex-end;
}
