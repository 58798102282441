.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr auto auto;
  padding-right: var(--spacing-8);
  overflow-y: auto;
  scroll-behavior: auto;

  & > * {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: var(--spacing-8) var(--spacing-6);
    border-bottom: solid 1px var(--border-color-disabled);
  }

  :nth-child(1) {
    border: none;
  }

  :nth-child(2) {
    grid-column: 2 / 5;
    border: none;
  }
}

.title {
  padding: var(--spacing-6);
  color: var(--font-color-second-accent);
  background-color: var(--background-color-secondary);
}
