@import '/src/styles/shared/breakpoints.scss';

.orderCardsWrapper {
  @include breakpoint(xl) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  gap: calc(2 * var(--spacing-1));
  align-items: center;
  justify-content: center;
  width: 100%;
}
