.tasksList {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
}

.taskWrapper {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
  width: 100%;
}

.taskIcon {
  width: var(--spacing-6);
  min-width: 10%;
  height: var(--spacing-6);
}

.emptyList {
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 100%;
  padding: var(--spacing-8);
  text-align: center;
}
