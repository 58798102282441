.usersPageFilters {
  display: flex;
  gap: 16px;
  margin: 0 0 var(--spacing-6) 0;

  .btnDeleteSeveral {
    padding: 7px var(--spacing-8);
    margin-left: auto;
  }
}
