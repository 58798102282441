.usersPageTableLine {
  position: relative;
  display: flex;
  gap: 26px;
  align-items: center;
  height: 44px;
  padding: 0 20px;
  border-bottom: 1px solid #ececec;

  label {
    span::before {
      margin: 0;
    }
  }

  .role {
    flex: 4;
    min-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name {
    flex: 3.5;
    min-width: 45px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .surname {
    flex: 5;
    min-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .email {
    flex: 7.5;
    min-width: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .date {
    width: 76px;
  }

  .status {
    span {
      justify-content: normal;
      min-width: 4.5rem;
      margin: 0;
      font-size: 13px;
    }
  }

  .btnDelete {
    padding: 0;
  }

  &.isSuperAdmin {
    .role {
      display: flex;
      gap: 7px;
      align-items: center;
      cursor: pointer;

      .iconOpenRoles {
        min-width: 10px;
      }
    }
  }
}

