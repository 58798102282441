.usersPageTableHeaderColumn {
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;

  .title {
    line-height: 150%;
    color: var(--font-color-second-accent);
  }

  &.role {
    flex: 4;
    min-width: 60px;
  }

  &.name {
    flex: 3.5;
    min-width: 45px;
  }

  &.surname {
    flex: 5;
    min-width: 60px;
  }

  &.email {
    flex: 7.5;
    min-width: 60px;
  }

  &.date {
    width: 76px;
  }

  &.status {
    width: 112px;
  }
}
