.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: auto;
}

.spinner {
  width: 50px;
  height: 50px;
  border-color: rgba(0, 0, 0, 0.1);
  border-style: solid;
  border-width: 5px;
  border-top-color: #000000;
  border-radius: 50%;
  animation: spin 1s ease-in-out infinite;

  &.s {
    width: 32px;
    height: 32px;
    border-width: 3px;
  }

  &.xs {
    width: 16px;
    height: 16px;
    border-width: 2px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
