.popUp {
  position: absolute;
  top: -10px;
  left: 35px;
  z-index: 9;
  width: 250px;
  padding: var(--spacing-7);
  background-color: var(--font-color-invert);
  border: 1px solid var(--border-color-hover-input);
  border-radius: 5%;
  box-shadow: -2px -4px 15px var(--border-color-hover-input), 2px 4px 15px var(--border-color-hover-input);
}

.popUp::after, .popUp::before {
  position: absolute;
  top: 10px;
  left: -20px;
  content: '';
  border: 10px solid transparent;
  border-right: 10px solid var(--border-color-hover-input);
}

.popUp::after {
  position: absolute;
  top: 10px;
  left: -19px;
  content: '';
  border: 10px solid transparent;
  border-right: 10px solid var(--font-color-invert);
}

.popUpText {
  width: 100%;
  font-family: var(--body-font-family);
  font-size: var(--smallRegular-font-size);
  font-weight: var(--smallRegular-font-weight);
  line-height: var(--smallRegular-line-height);
}

.filterName {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
  padding-bottom: var(--spacing-10);
}
