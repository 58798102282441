@import '/src/styles/shared/breakpoints.scss';

.wrapper {
  @include breakpoint(md) {
    flex-direction: row;
  }
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-7);
  align-items: center;
  width: 100%;
  padding: var(--spacing-6);
  background-color: var(--background-color-secondary);
  border-top: 1px solid #b3b3b3;
}

.text {
  flex: 1;
}
