@import '/src/styles/shared/breakpoints.scss';

.section {
  display: flex;
  gap: var(--spacing-7);
  justify-content: center;
  margin: auto;
}

.solution {
  width: 100%;
  max-width: var(--max-width);
  padding: var(--spacing-7);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  align-items: center;
  width: 100%;
  min-height: 100%;
}

.mainInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.mainInfoHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.title {
  font-size: var(--H3-font-size);
  font-weight: var(--H3-font-weight);
  line-height: var(--H3-line-height);
}

.favoriteBtn {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
  align-self: self-end;
  font-family: var(--font-raleway);
  font-size: var(--extraLargeRegular-font-size);
  font-weight: var(--normalRegular-font-weight);
  text-decoration-line: none;
  background: none;
  border: none;
}

.orderMainInfoBtn {
  width: 308px;
}

.starEmpty {
  path {
    fill: none;
  }
}

.mainInfoBody {
  @include breakpoint(md) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.priceWrapper {
  display: flex;
  gap: var(--spacing-9);
}

.price {
  display: flex;
  align-items: center;
  padding: var(--spacing-9) 0;
  font-size: var(--extraLargeRegular-font-size);
  font-weight: var(--normalBold-font-weight);
  line-height: var(--extraLargeRegular-line-height);
}

.text {
  display: flex;
  align-items: center;
  padding: var(--spacing-9) 0;
}

.tasks {
  @include breakpoint(md) {
    display: flex;
    flex-direction: row;
  }
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: var(--spacing-8);
  box-shadow: 2px 4px 4px 4px var(--background-color-secondary);
}

.tasksImg {
  @include breakpoint(md) {
    width: 50%;
  }
  display: flex;
  justify-content: center;
  width: 100%;
  padding: var(--spacing-8);
}

.tasksOptions {
  @include breakpoint(md) {
    width: 50%;
  }
  display: flex;
  flex-direction: column;
  gap: var(--spacing-8);
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 428px;
  padding: var(--spacing-8);
  background-color: var(--background-color-secondary);
  border-radius: var(--spacing-8);
}

.platform {
  width: 100%;
}

.platformTitle {
  padding-top: var(--spacing-1);
  padding-bottom: var(--spacing-2);
}

.platformLink {
  font-size: var(--H2-font-size);
  font-weight: var(--H2-font-weight);
  line-height: var(--H2-line-height);
  text-decoration: underline;
}

.platformInfo {
  @include breakpoint(md) {
    flex-direction: row;
  }
  display: flex;
  flex-direction: column;
  gap: var(--spacing-9);
  align-items: center;
  justify-content: space-between;
}

.plaformSlider {
  @include breakpoint(md) {
    width: 40%;
  }
  width: 100%;
  padding: 0 var(--spacing-3);
}

.imgWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 var(--spacing-3);
}

.stepImg {
  flex-basis: 100%;
  width: 100%;
  height: 350px;
  margin-bottom: var(--spacing-9);
  object-fit: contain;
}

.imgTitle {
  display: block;
  width: 100%;
  text-align: center;
}

.tasksEvents {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: var(--spacing-3) var(--spacing-5) var(--spacing-3) var(--spacing-1);
  background-color: var(--background-color-secondary);
  border-radius: var(--spacing-8);
}

.tasksTitle,
.reviewTitle {
  font-size: var(--H4-font-size);
  font-weight: var(--H4-font-weight);
  line-height: var(--H4-line-height);
}

.tasksEventsTitle {
  font-size: var(--H4-font-size);
  font-weight: var(--H4-font-weight);
  line-height: var(--H4-line-height);
  text-align: center;
}

.solutionReview {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-5);
  width: 100%;
}

.bottomOrderButtonWrapper {
  display: flex;
  justify-content: center;
  padding-top: 80px;
  padding-bottom: 190px;
}

.solutionVideoWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 860px;
  height: 440px;
  padding: var(--spacing-6);
}

.solutionVideo {
  width: 100%;
  max-width: 800px;
  height: 100%;
  max-height: 600px;
}

.orderBtn {
  width: 600px;
  height: 70px;
  font-size: var(--extraLargeBold-font-size);
  font-weight: var(--extraLargeBold-font-weight);
}
