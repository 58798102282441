%input, %select {
  flex: 1;
  width: 100%;
  max-height: 40px;
  padding: var(--spacing-8);
  border: 1px solid var(--background-color-inactive);
  border-radius: 4px;

  &:focus,
  &:hover {
    border: 1px solid var(--background-color-button-secondary);
  }
}

%select {
  padding: 0 0 0 8px;
  font-size: var(--normalRegular-font-size);
  font-weight: var(--normalRegular-font-weight);
  line-height: var(--normalRegular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
