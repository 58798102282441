.label {
  position: relative;
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.span {
  display: inline-flex;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;
  user-select: none;
}

.span::before {
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50%;
  border: 1px solid #4c4c4c;
  border-radius: 50%;
  transform: translateY(-50%);
}

.span::after {
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-left: 2px;
  content: '';
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 50%;
  transform: translateY(-50%);
}

.emptyChecked:checked + .span::after {
  background-color: transparent;
}

input:checked + .span::after {
  background-color: #326789;
}

