* {
  user-select: none;
}

.form,
.item {
  display: flex;
  flex-direction: column;
}

.newItem {
  margin: var(--spacing-7) 0 20px;
}

.newItem input,
.field input {
  min-height: 50px;
}

.newItem span,
.text {
  font-size: var(--smallRegular-font-size);
  font-weight: var(--normalBold-font-weight);
  line-height: var(--smallRegular-line-height);
}

.sharedButtonStyle {
  display: flex;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
  max-height: 34px;
  font-size: var(--smallRegular-font-size);
  line-height: 1;
}

.list {
  margin: 20px 0 40px;
}

.item:not(:last-child) {
  margin-bottom: 20px;
}

.text {
  margin-bottom: var(--spacing-9);
}

.line {
  display: flex;
  align-items: center;
}

.field {
  flex: 1 1 100%;
  font-size: var(--normalRegular-font-size);
  font-weight: var(--normalRegular-font-weight);
  line-height: var(--normalRegular-line-height);
  background-color: var(--background-color-fields);
  border: 1px solid var(--background-color-inactive);
  border-radius: var(--spacing-10);
}

.iconsGroup {
  display: flex;
  flex: 0 0 94px;
  gap: var(--spacing-6);
  justify-content: flex-end;
}

.iconsGroup > *:not(:last-child) {
  margin-left: var(--spacing-6);
}

.iconsGroup > * {
  cursor: pointer;
}

.buttonsGroup {
  display: flex;
  gap: var(--spacing-9);
  align-items: center;
  align-self: flex-end;
  margin-top: 20px;

  & button:first-child {
    height: 34px;
    padding: 0;
    font-size: var(--smallRegular-font-size);
  }
}

.cancelButton {
  font-size: var(--smallRegular-font-size);
  cursor: pointer;
}
