@import '../../style.module.scss';

.label {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: var(--spacing-9);
  height: 100%;
}

.input {
  @extend %input;
}

.inputError {
  border: 1px solid var(--border-color-warning);
}

.inputDisabled {
  background-color: var(--background-color-fields);

  &:hover {
    border: 1px solid var(--background-color-inactive);
  }
}

.inputIcon {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 20px;
  aspect-ratio: 1/1;
}

.inputWithIcon {
  padding-left: var(--spacing-3);
}
