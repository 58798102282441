.customLogo {
  display: grid;
  place-items: center;
  width: var(--spacing-3);
  height: var(--spacing-3);
  border: 2px solid #eeeeee;
  border-radius: 12px;
  box-shadow: 0 0 10px 4px #eeeeee;

  .customLogoText {
    font-size: 30px;
    font-weight: 700;
    line-height: 30px;
    text-shadow: inherit;
    text-transform: uppercase;
  }
}

.smallCustomLogo {
  flex: 0 0 25px;
  width: 25px;
  height: 25px;

  .smallCustomLogoText {
    font-size: 16px;
    line-height: initial;
  }
}
