.imageWrapper {
  display: flex;
  gap: var(--spacing-7);
  padding: var(--spacing-8) var(--spacing-6);
}

.image {
  min-width: var(--spacing-3);
  max-width: var(--spacing-3);
  min-height: var(--spacing-3);
  max-height: var(--spacing-3);
  overflow: hidden;
  border-radius: var(--spacing-9);
}

.activity {
  color: var(--font-color-disabled);

  &.isActive {
    color: var(--font-color-second-accent);
  }
}
