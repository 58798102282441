.eventForm {
  margin-bottom: var(--spacing-7);
}

.eventNumber {
  margin-bottom: var(--spacing-9);
  font-size: var(--smallRegular-font-size);
  font-weight: var(--normalBold-font-weight);
  line-height: var(--smallRegular-line-height);
}

.eventFlex {
  display: flex;

  button {
    margin-left: var(--spacing-7);
  }
}

.inputContainer {
  width: 100%;

  input {
    min-height: 50px;
  }
}

.eventButtons {
  display: flex;
  gap: var(--spacing-9);
  justify-content: flex-end;
  max-height: 34px;
  margin: var(--spacing-7) 0;

  & button:first-child {
    padding: 0;
    font-size: var(--smallRegular-font-size);
  }
}

.eventButtonsDisabled {
  display: none;
}

.addEventButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--smallRegular-font-size);
  line-height: 1;
}

.cancelEventButton {
  padding: var(--spacing-8) var(--spacing-6);
  font-size: var(--smallRegular-font-size);
  line-height: 1;
}
