.addImageBlock {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
  padding-bottom: var(--spacing-4);
  margin-top: var(--spacing-5);

  & textarea {
    min-height: 136px;
  }
}

.saveBtn {
  display: flex;
  grid-area: saveBtn;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  width: 120px;
  height: 34px;
  padding: var(--spacing-8) var(--spacing-8);
  margin-left: var(--spacing-10);

  &:active {
    transform: scale(0.97);
  }

  &:disabled {
    transform: scale(1);
  }
}

.buttonsBlock {
  display: flex;
  grid-column: 1/3;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--spacing-5);

  & button:first-child {
    min-width: 7rem;
    height: 34px;
    padding: 0;
  }
}

.imageLines {
  & > div:first-child {
    border-top: 1px solid rgb(179, 179, 179);
  }
}

.file div > span {
  margin-top: -40px;
}

.cardsLimit {
  margin: var(--spacing-6) 0;
  color: var(--font-color-error);
}
