.wrapper {
  display: grid;
  grid-template-columns: 1fr auto auto;
  padding-right: var(--spacing-8);
  overflow-y: auto;
  scroll-behavior: auto;

  & > * {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: var(--spacing-8) var(--spacing-6);
    border-bottom: solid 1px var(--border-color-disabled);
  }

  :nth-child(1) {
    grid-column: 1 / 4;
    border: none;
  }

  span:last-child {
    grid-column: 1 / 4;
  }
}

.title {
  padding: var(--spacing-6);
  color: var(--font-color-second-accent);
  background-color: var(--background-color-secondary);
}

.deleteModal > *:not(:last-child) {
  margin-bottom: 12px;
}

.deleteModal p {
  text-align: start;
}

.deleteModalTitle {
  font-size: 20px;
  font-weight: 500;
  text-align: start;
}

.deleteModalSubtitle,
.deleteModalSubtitle span,
.deleteModalCheckbox label > span {
  font-size: var(--smallRegular-font-size);
  font-weight: var(--smallRegular-font-weight);
  line-height: var(--smallRegular-line-height);
}

.deleteModalSubtitle span {
  display: inline-block;
}

.deleteModalCheckbox label {
  font-size: 10px;
  color: #b3b3b3;
}

.deleteModalCheckbox label > span::before {
  border: #b3b3b3 1px solid;
}
