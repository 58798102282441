.form {
  display: grid;
  grid-template:
    'name price' min-content
    'platform link' min-content
    'description _' min-content
    'saveBtn saveBtn' min-content / 1.3fr 1.2fr;
  grid-gap: 1rem;
}

.name {
  grid-area: name;
}

.price {
  grid-area: price;

  .priceLabel {
    margin: 0 0 var(--spacing-9) 0;
  }

  .priceFlex {
    display: flex;
    gap: var(--spacing-8);
  }
}

.platform {
  grid-area: platform;
}

.description {
  grid-area: description;

  label {
    padding-top: 0;
  }
}

.link {
  grid-area: link;
}

.saveBtn {
  display: flex;
  grid-area: saveBtn;
  align-items: center;
  justify-content: center;
  justify-self: flex-end;
  width: 120px;
  height: 34px;
  margin-top: var(--spacing-7);

  &:active {
    transform: scale(0.97);
  }

  &:disabled {
    transform: scale(1);
  }
}

.switcherBtnContainer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: var(--spacing-6);

  .activity {
    color: var(--font-color-disabled);

    &.isActive {
      color: var(--font-color-second-accent);
    }
  }
}
