.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
}

.tabsWrapper {
  display: flex;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-7);
}
