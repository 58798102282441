@import '/src/styles/shared/breakpoints.scss';

.solutions {
  justify-self: start;
}

.imageWrapper {
  padding: var(--spacing-8) 0;
}

.image {
  overflow: hidden;
  border-radius: var(--spacing-9);

  & img {
    max-width: var(--spacing-3);
    max-height: var(--spacing-3);
  }
}

.activity {
  color: var(--font-color-disabled);

  &.isActive {
    color: var(--font-color-second-accent);
  }
}
