.section {
  display: flex;
  gap: var(--spacing-7);
  justify-content: center;
  margin: auto;
}

.platform {
  width: 100%;
  max-width: var(--max-width);
  padding: var(--spacing-7);
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  width: 100%;
  min-height: 100%;
}

.navItem {
  color: var(--font-color-disabled);
}

.activeTab {
  color: var(--font-color-base);
}

.header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-6);
  margin: var(--spacing-7) 0;
}

.headerContentTop {
  display: flex;
  justify-content: space-between;
}

.priceWrapper {
  display: flex;
  gap: var(--spacing-9);
}

.price {
  display: flex;
  align-items: center;
  font-size: var(--extraLargeRegular-font-size);
  font-weight: var(--normalBold-font-weight);
  line-height: var(--extraLargeRegular-line-height);
}

.headerContentBottom {
  display: flex;
  gap: var(--spacing-2);
  align-items: center;
}

.text {
  max-width: 767px;
  max-height: 100px;
  overflow-y: auto;
  word-break: break-all;
}

.favoriteButton {
  display: flex;
  gap: var(--spacing-8);
  align-items: center;
  align-self: self-end;
  text-decoration-line: none;
  background: none;
  border: none;
}

.favoriteLoader {
  width: 20px;
  height: 20px;
}

.starEmpty {
  path {
    fill: none;
  }
}

.logo {
  flex-basis: 20%;
  max-width: 15%;
  height: 100%;
}

.descriptionSection {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-4);
  padding-bottom: var(--spacing-7);
  border-bottom: 1px solid var(--border-color-disabled);
}

.descriptionWrapper {
  display: flex;
}

.descriptionColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: var(--spacing-7);
}

.navInfo {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 0;
  margin-bottom: var(--spacing-4);

  li {
    display: flex;
    justify-content: center;
    padding: 3px var(--spacing-9);
    border-right: 1px solid var(--border-color-disabled);
  }

  li:last-of-type {
    border-right: none;
  }

  li:first-of-type {
    padding-left: 0;
  }

  a {
    color: var(--font-color-disabled);
    text-align: center;

    &:active,
    &:hover {
      color: var(--font-color-base);
    }

    &::after {
      position: relative;
      left: 50%;
      display: block;
      width: 0;
      height: 2px;
      content: '';
      background-color: #ffaa00;
      transition: width 0.3s ease-in-out;
      transform: translateX(-50%);
    }

    &:hover::after,
    &:active::after {
      width: 100%;
      color: var(--font-color-base);
    }
  }

  .active {
    color: var(--font-color-base);
  }
}

.link {
  font-weight: 600;
  text-decoration: underline;
}

.sectionTitle {
  display: flex;
  gap: 10px;
  color: var(--font-color-base);
}

.sectionTitleHeader {
  display: inline-block;
  font-size: var(--H3-font-size);
  font-weight: var(--H3-font-weight);
  line-height: var(--H3-line-height);
}

.sectionTitleName {
  display: inline-block;
  font-size: var(--extraLargeRegular-font-size);
  font-weight: var(--extraLargeRegular-font-weight);
  line-height: var(--extraLargeRegular-line-height);
}

.solutionsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-7);
}

.solutionItem {
  display: flex;
  flex-basis: 30%;
  justify-content: center;
  border: 1px solid var(--border-color-disabled);
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.characteristics {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  align-items: center;
}

.characteristicsButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.solutions,
.reviews {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-3);
  max-width: 100%;
}
