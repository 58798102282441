.usersPageFilterDates {
  .inputs {
    display: flex;
    gap: 12px;
    align-items: center;
    margin: 0 0 var(--spacing-7) 0;

    .inputWithIcon {
      position: relative;
      display: flex;
      align-items: center;
      height: 18px;

      input {
        width: 87px;
        font-size: 16px;

        &:disabled {
          background-color: transparent;
        }

        &::placeholder {
          font-size: 19px;
        }
      }
    }

    .line {
      width: 9px;
      border: 1px solid var(--border-color-disabled);
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;

    button {
      min-width: 7rem;
      padding: var(--spacing-9) var(--spacing-8);
      font-size: var(--smallRegular-font-size);
    }

    .btnClear {
      border: 1px solid var(--background-color-inactive);
      border-radius: 4px;

      &:hover {
        border: 1px solid var(--font-color-base);
      }
    }
  }
}
