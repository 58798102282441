@import '/src/styles/shared/breakpoints.scss';

.button {
  display: flex;
  gap: var(--spacing-9);
  align-items: center;
  padding: 0;
  color: #326789;
  background: none;
  border: none;
}
