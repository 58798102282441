.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  pointer-events: all;
  background: rgba(#000000, 0.4);
  opacity: 1;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
  padding: var(--spacing-5);
  background-color: #ffffff;

  p {
    text-align: center;
  }
}

.wrapper {
  display: flex;
  gap: var(--spacing-7);
  justify-content: center;
  padding-top: 20px;
}
