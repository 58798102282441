.wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-9);
  width: 100%;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-8);
  font-size: var(--normalRegular-font-size) !important;
  background-color: transparent;
  border: 1px solid var(--border-color-hover-input);
  border-radius: var(--spacing-10);

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-size-adjust: 100%;
  }

  span {
    display: flex;
    align-items: center;
  }

  &:hover:not(.inputDisabled) {
    border: 1px solid var(--background-color-button-secondary);
  }

  &:active:not(.inputDisabled),
  &:focus-within {
    border: 1px solid var(--background-color-button-secondary);
  }

  &:disabled input {
    color: var(--font-color-disabled);
  }
}

.marginRight {
  margin-right: var(--spacing-8);
}

.marginLeft {
  margin-left: var(--spacing-8);
}

.input {
  width: 100%;
  padding: var(--spacing-8) 0;
  margin: 0;
  background-color: inherit;
  outline: none;
}

.uniqueInput {
  width: 100%;
  padding: var(--spacing-8) 0;
  margin: 0;
  margin-bottom: 10px;
  background-color: inherit;
  outline: none;
}

.warningInput {
  background-color: var(--background-color-button);
  border: 1px solid var(--border-color-warning);

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    box-shadow: inset 0 0 0 50px #ffffff;
  }
}

.warningText {
  position: absolute;
  bottom: calc(-1.25 * var(--spacing-7));
  left: 0;
  display: flex;
  gap: var(--spacing-9);
  align-items: center;
  font-size: var(--smallRegular-font-size);
  line-height: var(--smallRegular-line-height);
  color: var(--font-color-warning);
  white-space: nowrap;
}

.inputDisabled {
  background-color: var(--background-color-fields);
}
