.usersPagePagination {
  display: flex;
  gap: var(--spacing-7);
  align-items: center;
  padding: 0 0 var(--spacing-4) 0;

  .pagination {
    display: flex;
    align-items: center;

    button:disabled {
      opacity: 0.3;
    }

    .pages {
      display: flex;
      gap: var(--spacing-9);

      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: var(--spacing-5);
        font-weight: 500;
        color: #666666;
        cursor: pointer;

        &.active {
          color: #121211;
          background: var(--background-color-secondary);
          border-radius: 4px;
        }
      }
    }
  }

  .amount {
    font-size: 14px;
    line-height: 150%;
    color: #666666;
  }
}
