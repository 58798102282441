.usersPageChangeRole {
  position: absolute;
  top: 5px;
  left: 50px;
  z-index: 2;
  display: flex;
  padding: var(--spacing-8);
  cursor: auto;
  background-color: var(--background-color);
  border: 1px solid var(--background-color-secondary);
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(16, 24, 40, 0.08), 0 0 7px 0 rgba(0, 0, 0, 0.03);

  .iconClose {
    height: fit-content;
    padding: 7px 7px 7px 0;
    cursor: pointer;

    svg {
      display: block;
    }
  }

  .content {
    .options {
      li {
        margin: 0 0 var(--spacing-10) 0;

        &:last-child {
          margin: 0;
        }
      }
    }

    button {
      padding: var(--spacing-9) var(--spacing-8);
      margin: var(--spacing-8) 0 0 0;
      font-size: var(--smallRegular-font-size);
    }
  }
}
