.solutions {
  justify-self: start;
}

.imageWrapper {
  padding: var(--spacing-8) 0;
}

.image {
  overflow: hidden;
  border-radius: var(--spacing-9);
}
